
import React, { useState, useEffect } from "react";
import { createClient } from "@sanity/client";
import "../../styles/heroBanner.css";

// Set up the client configuration
const client = createClient({
  projectId: "r6d5009o",
  dataset: "production",
  apiVersion: "2023-11-05",
  useCdn: true,
});

const query = `*[_type == "heroBanner"]{
  title,
  subtitle,
  imageMobile{
    asset->{
      _id,
      url
    },
    alt
  },
  imageDesktop{
    asset->{
      _id,
      url
    },
    alt
  },
  cta{
    label,
    link
  }
}`;

const HeroBanner = () => {
  const [slides, setSlides] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const data = await client.fetch(query);
        setSlides(data);
      } catch (error) {
        console.error("Sanity fetch error:", error);
      }
    };

    fetchSlides();
  }, []);

  const goToSlide = (index) => {
    if (transitioning) return;
    setTransitioning(true);
    setActiveSlide(index);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTransitioning(false);
    }, 500); // This should match your CSS transition duration
    return () => clearTimeout(timer);
  }, [activeSlide]);

  const goToPrevious = () => {
    setActiveSlide((prev) => (prev > 0 ? prev - 1 : slides.length - 1));
  };

  const goToNext = () => {
    setActiveSlide((prev) => (prev < slides.length - 1 ? prev + 1 : 0));
  };

  if (slides.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="parent-container">
      <div className="hero-banner">

      <div className="svg-animation">
        <svg
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 1000 1000"
          style={{ enableBackground: "new 0 0 1000 1000" }}
        >
          {/* Stroke ring */}
          <circle className="st0" cx="500" cy="500" r="302.8">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 500 500"
              to="360 500 500"
              dur="100s"
              repeatCount="indefinite"
            />
          </circle>
          {/* Inner ring */}
          <circle className="st1" cx="500" cy="500" r="237.7">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 500 500"
              to="360 500 500"
              dur="40s"
              repeatCount="indefinite"
            />
          </circle>
          {/* Outer ring */}
          <circle className="st2" cx="500" cy="500" r="366.8">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 500 500"
              to="-360 500 500"
              dur="50s"
              repeatCount="indefinite"
            />
          </circle>
          {/* Outer thin ring */}
          <circle className="st3" cx="500" cy="500" r="385.1" />
        </svg>
      </div>


     
     

      {slides.map((slide, index) => (
<React.Fragment key={index}>
  {slide.imageMobile && slide.imageMobile.asset && (
    <img
      src={`${slide.imageMobile.asset.url}?v=${index}`}
      alt={slide.imageMobile.alt || "Slide"}
      className={`mobile ${index === activeSlide ? "active" : ""}`}
      style={{
        transition: "opacity 0.5s ease-in-out",
        opacity: index === activeSlide ? 1 : 0,
      }}
      loading="lazy"
    />
  )}
  {slide.imageDesktop && slide.imageDesktop.asset && (
    <img
      src={`${slide.imageDesktop.asset.url}?v=${index}`}
      alt={slide.imageDesktop.alt || "Slide"}
      className={`desktop ${index === activeSlide ? "active" : ""}`}
      style={{
        transition: "opacity 0.5s ease-in-out",
        opacity: index === activeSlide ? 1 : 0,
      }}
      loading="lazy"
    />
  )}
</React.Fragment>
))}

      <div className="hero-text">
        <h1>{slides[activeSlide].title}</h1>
        <p className="subtitle">{slides[activeSlide].subtitle}</p>
        {slides[activeSlide].cta && (
          <a href={slides[activeSlide].cta.link}>
            <button className="ctaAll">
              {slides[activeSlide].cta.label}
            </button>
          </a>
        )}
      </div>
      <div className="navigationHero">
        <button
          className="nav-arrow prev-arrow"
          onClick={goToPrevious}
          aria-label="Previous slide"
        >
          &#171;
        </button>

        {slides.map((_, index) => (
          <button
            key={index}
            className={`navigation-dot ${
              index === activeSlide ? "active" : ""
            }`}
            onClick={() => goToSlide(index)}
            aria-label={`Slide ${index + 1}`}
          />
        ))}

        <button
          className="nav-arrow next-arrow"
          onClick={goToNext}
          aria-label="Next slide"
        >
          &#187;
        </button>
      </div>
    </div>
  </div>
);
};

export default HeroBanner;


// import React, { useState, useEffect } from "react";
// import { createClient } from "@sanity/client";
// import "../../styles/heroBanner.css";

// // Set up the client configuration
// const client = createClient({
//   projectId: "r6d5009o",
//   dataset: "production",
//   apiVersion: "2023-11-05",
//   useCdn: true,
// });

// const query = `*[_type == "heroBanner"]{
//     title,
//     subtitle,
//     imageMobile{
//       asset->{
//         _id,
//         url
//       },
//       alt
//     },
//     imageDesktop{
//       asset->{
//         _id,
//         url
//       },
//       alt
//     },
//     cta{
//       label,
//       link
//     }
//   }`;

// const HeroBanner = () => {
//   const [slides, setSlides] = useState([]);
//   const [activeSlide, setActiveSlide] = useState(0);
//   const [transitioning, setTransitioning] = useState(false);

//   useEffect(() => {
//     const fetchSlides = async () => {
//       try {
//         const data = await client.fetch(query);
//         setSlides(data);
//       } catch (error) {
//         console.error("Sanity fetch error:", error);
//       }
//     };

//     fetchSlides();
//   }, []);

//   const goToSlide = (index) => {
//     if (transitioning) return;
//     setTransitioning(true);
//     setActiveSlide(index);
//   };

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setTransitioning(false);
//     }, 500); // This should match your CSS transition duration
//     return () => clearTimeout(timer);
//   }, [activeSlide]);

//   const goToPrevious = () => {
//     setActiveSlide((prev) => (prev > 0 ? prev - 1 : slides.length - 1));
//   };

//   const goToNext = () => {
//     setActiveSlide((prev) => (prev < slides.length - 1 ? prev + 1 : 0));
//   };

//   if (slides.length === 0) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="parent-container">
//       <div className="hero-banner">
//         <div className="svg-animation">
//           <svg
//             version="1.1"
//             x="0px"
//             y="0px"
//             viewBox="0 0 1000 1000"
//             style={{ enableBackground: "new 0 0 1000 1000" }}
//           >
//             {/* Stroke ring */}
//             <circle className="st0" cx="500" cy="500" r="302.8">
//               <animateTransform
//                 attributeType="xml"
//                 attributeName="transform"
//                 type="rotate"
//                 from="0 500 500"
//                 to="360 500 500"
//                 dur="100s"
//                 repeatCount="indefinite"
//               />
//             </circle>
//             {/* Inner ring */}
//             <circle className="st1" cx="500" cy="500" r="237.7">
//               <animateTransform
//                 attributeType="xml"
//                 attributeName="transform"
//                 type="rotate"
//                 from="0 500 500"
//                 to="360 500 500"
//                 dur="40s"
//                 repeatCount="indefinite"
//               />
//             </circle>
//             {/* Outer ring */}
//             <circle className="st2" cx="500" cy="500" r="366.8">
//               <animateTransform
//                 attributeType="xml"
//                 attributeName="transform"
//                 type="rotate"
//                 from="0 500 500"
//                 to="-360 500 500"
//                 dur="50s"
//                 repeatCount="indefinite"
//               />
//             </circle>
//             {/* Outer thin ring */}
//             <circle className="st3" cx="500" cy="500" r="385.1" />
//           </svg>
//         </div>

//         {slides.map((slide, index) => (
//           <React.Fragment key={index}>
//             {slide.imageMobile && slide.imageMobile.asset && (
//               <picture
//                 className={`mobile ${index === activeSlide ? "active" : ""}`}
//                 style={{
//                   transition: "opacity 0.5s ease-in-out",
//                   opacity: index === activeSlide ? 1 : 0,
//                 }}
//               >
//                 <source
//                   srcSet={`${slide.imageMobile.asset.url.replace(
//                     ".jpg",
//                     ".webp"
//                   )}?v=${index}`}
//                   type="image/webp"
//                 />
//                 <source
//                   srcSet={`${slide.imageMobile.asset.url}?v=${index}`}
//                   type="image/jpeg"
//                 />
//                 <img
//                   src={`${slide.imageMobile.asset.url}?v=${index}`}
//                   alt={slide.imageMobile.alt || "Slide"}
//                   loading="lazy"
//                 />
//               </picture>
//             )}
//             {slide.imageDesktop && slide.imageDesktop.asset && (
//               <picture
//                 className={`desktop ${index === activeSlide ? "active" : ""}`}
//                 style={{
//                   transition: "opacity 0.5s ease-in-out",
//                   opacity: index === activeSlide ? 1 : 0,
//                 }}
//               >
//                 <source
//                   srcSet={`${slide.imageDesktop.asset.url.replace(
//                     ".jpg",
//                     ".webp"
//                   )}?v=${index}`}
//                   type="image/webp"
//                 />
//                 <source
//                   srcSet={`${slide.imageDesktop.asset.url}?v=${index}`}
//                   type="image/jpeg"
//                 />
//                 <img
//                   src={`${slide.imageDesktop.asset.url}?v=${index}`}
//                   alt={slide.imageDesktop.alt || "Slide"}
//                   loading="lazy"
//                 />
//               </picture>
//             )}
//           </React.Fragment>
//         ))}

//         <div className="hero-text">
//           <h1>{slides[activeSlide].title}</h1>
//           <p className="subtitle">{slides[activeSlide].subtitle}</p>
//           {slides[activeSlide].cta && (
//             <a href={slides[activeSlide].cta.link}>
//               <button className="ctaAll">
//                 {slides[activeSlide].cta.label}
//               </button>
//             </a>
//           )}
//         </div>
//         <div className="navigationHero">
//           <button
//             className="nav-arrow prev-arrow"
//             onClick={goToPrevious}
//             aria-label="Previous slide"
//           >
//             &#171;
//             <span className="visually-hidden">Previous slide</span>
//           </button>

//           {slides.map((_, index) => (
//             <button
//               key={index}
//               className={`navigation-dot ${
//                 index === activeSlide ? "active" : ""
//               }`}
//               onClick={() => goToSlide(index)}
//               aria-label={`Slide ${index + 1}`}
//             />
//           ))}

//           <button
//             className="nav-arrow next-arrow"
//             onClick={goToNext}
//             aria-label="Next slide"
//           >
//             &#187;
//             <span className="visually-hidden">Next slide</span>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HeroBanner;
