import React, { useEffect, useState } from 'react';
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import '../../styles/servicesSection.css'; // Make sure this CSS file exists
import ServiceCard from '../ServiceCard';

const client = createClient({
  projectId: 'r6d5009o',
  dataset: 'production',
  apiVersion: '2023-11-05', // use the current date in 'YYYY-MM-DD' format
  useCdn: true,
});

// Set up the image URL builder function
const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const ServicesSection = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    client.fetch('*[_type == "serviceCard"]').then((data) => {
      setServices(data);
    });
  }, []);

  return (
    <section className="services-section">
      <h2>Nuestros Servicios</h2>
      <div className="services-container">
        {services.map((service) => (
          <ServiceCard
            key={service._id}
            title={service.title}
            text={service.text}
            icon={urlFor(service.icon).url()} // Assuming 'icon' is the image field in your schema
          />
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
