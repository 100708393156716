import React, { useEffect, useState } from 'react';
import { createClient } from '@sanity/client';
import InfoCard from './infocard';

const client = createClient({
    projectId: 'r6d5009o',
    dataset: 'production',
    apiVersion: '2023-11-05', // use the current date in 'YYYY-MM-DD' format
    useCdn: true,
  });

const CardsContainer = () => {
  const [cardsData, setCardsData] = useState([]);

  useEffect(() => {
    client.fetch('*[_type == "infoCard"]').then((data) => {
        setCardsData(data);
    });
  }, []);

  return (
    <div className='infoCardCont'>
      {cardsData.map(card => (
        <InfoCard category={card.category} title={card.title} text={card.text} />
      ))}
    </div>
  );
};

export default CardsContainer;
