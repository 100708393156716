import React from 'react';
import { Helmet } from 'react-helmet';
import ServicesSection from './Sections/ServicesSection';
import Soluciones from './Sections/SolucionesSection';
import DifferencesSection from './Sections/DifferenceSection';
import BusinessPartnersSection from './Sections/BusinessPartnersSection';
import SocialProof from './Sections/SocialSetion';

function ServiciosPage() {
    return (
        <div>
          <Helmet>
    <title>Vargo - Soluciones Innovadoras en Seguros y Gestión de Riesgos</title>
    <meta name="description" content="Descubre los servicios de Vargo: Broker de seguros, gestión de riesgos, promoción y consultoría en seguros. Expertos en soluciones personalizadas para proteger tus bienes y negocios en México." />
    <meta name="keywords" content="Servicios de Seguros, Gestión de Riesgos, Consultoría en Seguros, Seguros Personalizados, Innovación en Seguros, Protección de Bienes" />
    <meta name="author" content="Vargo Seguros" />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://vargo.mx/servicios" />
    <meta property="og:title" content="Vargo - Soluciones Innovadoras en Seguros y Gestión de Riesgos" />
    <meta property="og:description" content="En Vargo ofrecemos servicios integrales de seguros y gestión de riesgos, adaptados a las necesidades individuales para una protección efectiva de activos personales y comerciales." />
    <meta property="og:url" content="https://vargo.mx/servicios" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="%PUBLIC_URL%/logo192.jpg" />
    <meta property="og:site_name" content="Vargo Seguros" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Vargo - Soluciones Innovadoras en Seguros y Gestión de Riesgos" />
    <meta name="twitter:description" content="En Vargo ofrecemos servicios integrales de seguros y gestión de riesgos, adaptados a las necesidades individuales para una protección efectiva de activos personales y comerciales." />
    <meta name="twitter:image" content="%PUBLIC_URL%/logo192.jpg" />
    <meta name="twitter:site" content="@VargoSeguros" />
    <meta name="twitter:creator" content="@VargoSeguros" />
</Helmet>

          <ServicesSection />
          <Soluciones />
          <BusinessPartnersSection />
          <DifferencesSection />
          <SocialProof />
        </div>
    );
}

export default ServiciosPage;
