import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Parallax } from 'react-scroll-parallax';
import '../../styles/aboutUs.css'; 
import logo from '../../logo.png';
import CardsContainer from '../cardContainer';

const QuienesSomos = () => {
  // Esta animación hará que el texto aparezca deslizándose desde la izquierda
  const fadeIn = useSpring({ 
    from: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
    to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  });

  return (
    <section className="about-us">
      <div className='imageText'>
      <Parallax className="parallax-image" y={[40, -40]} tagOuter="figure">
        {/* La imagen de fondo aquí tendrá el efecto parallax */}
      </Parallax>
      <animated.div style={fadeIn} className="innerCont">
     
        <h2>¿Quiénes Somos??</h2>
        <img src={logo} alt="Logo" className="logoSmall" />
        <p>Somos un grupo destacado en el mercado mexicano de seguros, conocidos por ser el único grupo que ofrece un amplio abanico de servicios de intermediación. Con más de 40 años de experiencia, nos enfocamos en brindar soluciones personalizadas para cada cliente, asegurando su protección ante cualquier eventualidad.</p>
      </animated.div>
      </div>
      <div style={{margin: "4em auto", background: "#a3e2f599"}}>
      <h1>Acerca de Nosotros »»</h1>
      <div className='cardsSection'>
        <CardsContainer />
      </div>
      </div>

      <div className='imageText'>
      <animated.div style={fadeIn} className="innerCont">
        <h2>Nuestra Misión</h2>
        <h3>Compromiso con el Cliente:</h3>
        <p>Nos distinguimos por nuestro servicio inmediato y asesoramiento de calidad. Estamos comprometidos en proteger lo que nuestros clientes más valoran, transformando conocimiento en valor y cuidando óptimamente el presupuesto de cada uno.
</p>
      </animated.div>
      <Parallax className="parallax-image" y={[40, -40]} tagOuter="figure">
        {/* La imagen de fondo aquí tendrá el efecto parallax */}
      </Parallax>

      </div>


    </section>
  );
};

export default QuienesSomos;
