import React from 'react';
import '../styles/footer.css';
import logo from '../logo.png';

const Footer = () => {
  return (
    <footer className="App-footer">
        <div className="footer-top">
        <img src={logo} alt="Vargo Logo" className='footer-logo'/>
        <nav className="footer-nav">
        <a href="/"><i className="fas fa-home"></i> Inicio</a>
          <a href="/nosotros"><i className="fas fa-users"></i> Acerca de Nosotros</a>
          <a href="/servicios"><i className="fas fa-briefcase"></i> Servicios y Ventajas</a>
          <a href="/blog"><i className="fas fa-blog"></i> BLOG</a>
          <a href="/contact"><i className="fas fa-envelope"></i> Contáctanos</a>
        </nav>
      </div>

      {/* Middle section for contact and social media links */}
      <div className="footer-middle">
        <div className="contact-info">
        <a href="mailto:hola@vargo.mx" style={{ textDecoration: 'none', color: 'inherit' }}>
        <p><i className="fas fa-envelope"></i> hola@vargo.mx</p>
        </a>
        <p><i className="fas fa-map-marker-alt"></i> Business Center Torre Palmas - Metepec, EdoMex</p>
          <p><i className="fas fa-map-marker-alt"></i> WeWork Artz - Alvaro Obregon, CDMX</p>
        </div>

        <div className="social-media-links">
          <p>Siguenos: </p>
  <a href="https://www.facebook.com/VARGOMX?mibextid=LQQJ4d"><i className="fab fa-facebook-f"></i><span className="visually-hidden">Facebook</span></a>
  <a href="https://www.instagram.com/vargo_mx?igsh=OXV4N3V2ZnJzdWZ0"><i className="fab fa-instagram"></i><span className="visually-hidden">Instagram</span></a>
  {/* <a href="http://linkedin.com"><i className="fab fa-linkedin-in"></i><span className="visually-hidden">LinkedIn</span></a> */}
</div>

      </div>

      {/* Bottom section for legal and extra information */}
      <div className="footer-bottom">
        <div className="legal-info">
          <a href="/terms">Terminos de Uso </a> | 
          <a href="/privacy"> Aviso de Privacidad</a>
          <address>
            Copyright © 2023 VARGO - Elevate Your Business Potential with 
            <a href="https://rebolvertech.com/" target="_blank" rel="noopener noreferrer"> Rebolver Technology Consulting</a>.
          </address>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
