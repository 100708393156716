import React, { useState, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import '../../styles/allBlogs.css';

// Set up Sanity client
const client = createClient({
    projectId: "r6d5009o",
    dataset: "production",
    apiVersion: "2023-11-05",
    useCdn: true,
});

// Image URL Builder
const builder = imageUrlBuilder(client);
function urlFor(source) {
    return builder.image(source);
}

// BlogPost component
const BlogPost = ({ id, title, description, content, imageUrl, createdAt, author }) => {
    const authorName = author
    const [showFullContent, setShowFullContent] = useState(false);

    const stripHtml = (html) => {
        // Create a new div element
        var temporaryDiv = document.createElement("div");
        // Set the HTML content with the provided
        temporaryDiv.innerHTML = html;
        // Get the text content from the div
        return temporaryDiv.textContent || temporaryDiv.innerText || "";
    };

    // Function to trim content to 150 characters
    const trimContent = (text) => {
        const plainText = stripHtml(text);
        return plainText.length > 150 ? plainText.substring(0, 150) + '...' : plainText;
    };

    // Format the date
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };
    const postDate = formatDate(createdAt);

    return (
        <div className="blog-card">
            <div className="meta">
                <div className="photo" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                <ul className="details">
                    <li className="author">{authorName}</li>
                    <li className="date">{postDate}</li>
                </ul>
            </div>
            <div className="description">
                <h1>{title}</h1>
                <h2>{description}</h2>

                {
                    showFullContent ? 
                    <div className='innerHtml' dangerouslySetInnerHTML={{ __html: content }} /> :
                    <p>{trimContent(content)}</p>
                }

                <p className="read-more ctaAll">
                    <a href={`/blog/${id}`} onClick={() => setShowFullContent(!showFullContent)}>
                        {showFullContent ? 'Ver Menos' : 'Ver Mas'}
                    </a>
                </p>
            </div>
        </div>
    );
};




const AllBlogs = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        client.fetch('*[_type == "blogPost"] | order(_createdAt desc)')
            .then((data) => {
                setPosts(data);
            });
    }, []);

    return (
        <section className="blog-section">
            <h2>Blog</h2>
            <div className="blog-posts">
                {posts.map(post => (
                    <BlogPost
                        key={post._id}
                        id={post._id}
                        title={post.title}
                        description={post.description}
                        content={post.content}
                        imageUrl={urlFor(post.image).url()}
                        createdAt={post._createdAt} // Pass the createdAt date here
                        author={post.author}
                    />
                ))}
            </div>
        </section>
    );
};
export default AllBlogs;
