import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';


import { ParallaxProvider } from 'react-scroll-parallax';
import './App.css';
import Header from './Components/Nav';
import HomePage from './Components/HomePage';
import Footer from './Components/Footer'; // Import the Footer component
import NosotrosPage from './Components/NosotrosPage';
import ServiciosPage from './Components/ServiciosPage'
import Blog from './Components/Blogs';
import BlogPostPage from './Components/BlogPostPage';
import ContactPage from './Components/ContactPage';
import Terminos from './Components/Terminos';
import Privacy from './Components/Privacy';

function App() {
  return (
    
    <Router>
      <Helmet>
        <title>Vargo - Líderes en Intermediación de Seguros y Gestión de Riesgos en México</title>
        <meta name="description" content="Vargo, líder en intermediación de seguros en México, especializada en gestión de riesgos y soluciones de seguros personalizadas. Destacamos en tecnología de salud y prácticas empresariales sostenibles." />
        <meta name="keywords" content="Intermediación de Seguros, Gestión de Riesgos, Seguros Personalizados, Tecnología de Salud, Prácticas Sostenibles, Mercado de Seguros en México" />
        <meta name="author" content="Vargo Seguros" />
        <meta name="copyright" content="Copyright © 2023 VARGO Seguros. Todos los derechos reservados." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="http://www.vargo.mx" />
        <meta property="og:title" content="Vargo - Líderes en Intermediación de Seguros en México" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.vargo.mx" />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.jpg" />
        <meta property="og:description" content="Servicios expertos de intermediación de seguros y gestión de riesgos adaptados a tus necesidades. Descubre el enfoque innovador de Vargo en el sector asegurador mexicano." />
        <meta property="og:site_name" content="Vargo Seguros" />
      </Helmet>
      <ParallaxProvider>
        <div className="App">
          <header className="App-header">
            <Header />
          </header>
          <div className='content'>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/nosotros" element={<NosotrosPage />} />
              <Route path='/servicios' element={<ServiciosPage />} />
              <Route path='/blog' element={<Blog />} />
              <Route path="/blog/:id" element={<BlogPostPage />} />
              <Route path='/contact' element={<ContactPage />} />
              <Route path='/terms' element={<Terminos />} />
              <Route path='/privacy' element={<Privacy />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </ParallaxProvider>
    </Router>
  );
}



export default App;
