import React, { useState, useEffect, useRef } from 'react';
import { createClient } from '@sanity/client';
import '../../styles/businessPartners.css';

const client = createClient({
    projectId: "r6d5009o",
    dataset: "production",
    apiVersion: "2023-11-05",
    useCdn: true,
});

const BusinessPartnersSection = () => {
  const [partners, setPartners] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(0); // Declare this state at the top
  const logosContainerRef = useRef(null);

  useEffect(() => {
    const query = '*[_type == "clientLogo"]|order(position asc){..., logo{..., asset->}}';
    client.fetch(query).then((data) => {
      setPartners(data.map(partner => ({
        ...partner,
        imageUrl: partner.logo.asset.url
      })));
    });
  }, []);

  useEffect(() => {
    if (imagesLoaded === partners.length * 2 && logosContainerRef.current) {
      const logosWidth = Array.from(logosContainerRef.current.children)
        .reduce((acc, child) => acc + child.offsetWidth, 0);
      const totalWidth = logosWidth * 2; // Since array is duplicated
      console.log("Total Width: ", totalWidth); // Debugging log
      logosContainerRef.current.style.width = `${totalWidth}px`;

      let animationDuration = totalWidth / 100; // Adjust base speed here for desktop

      // Adjust speed for mobile
      if (window.innerWidth <= 740) {
        animationDuration = totalWidth / 75; // Adjust speed here for mobile
      }
      console.log("Animation Duration: ", animationDuration); // Debugging log
      logosContainerRef.current.style.animationDuration = `${animationDuration}s`;
    }
  }, [imagesLoaded, partners]);

  const handleImageLoad = () => {
    setImagesLoaded(prev => prev + 1);
  };

  return (
    <div className="business-partners-section">
      <h2>Nuestros Socios de Negocio</h2>
      <p>Trabajamos con las principales aseguradoras del país</p>

<div className="logos-container" ref={logosContainerRef}>
  {partners.concat(partners).map((partner, index) => (
    <picture key={index} className="partner-logo">
      <source srcSet={partner.imageUrl.replace('.jpg', '.webp')} type="image/webp" />
      <source srcSet={partner.imageUrl} type="image/jpeg" /> {/* Assuming original is JPEG */}
      <img src={partner.imageUrl} alt={partner.companyName} onLoad={handleImageLoad} />
    </picture>
  ))}
</div>
</div>
  );
};

export default BusinessPartnersSection;
