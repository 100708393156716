import React from 'react';
import { useSpring, animated } from 'react-spring';
// import { Parallax } from 'react-scroll-parallax';
import '../../styles/aboutUs.css'; 
import logo from '../../logo.png';

const AboutUs = () => {
  // Esta animación hará que el texto aparezca deslizándose desde la izquierda
  const fadeIn = useSpring({ 
    from: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
    to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  });

  return (
    <section className="about-us">
      <div className='imageText'>
      <animated.div className="parallax-image" y={[40, -40]} tagOuter="figure">
        {/* La imagen de fondo aquí tendrá el efecto parallax */}
      </animated.div>
      <animated.div style={fadeIn} className="innerCont">
      <img src={logo} alt="Logo" className="logoSmall" />
        <h2>¿¿Quiénes Somos?</h2>
        <p>En Vargo, nos distinguimos por ser el único grupo en el mercado mexicano de seguros que ofrece un amplio abanico de servicios de intermediación. Nuestro enfoque integral y personalizado nos permite brindar soluciones a la medida de cada cliente, asegurando su tranquilidad y protección ante cualquier eventualidad.</p>
        <a href="/nosotros">
              <button className='ctaAll'>Acerca de Nosotros</button>
            </a>
      </animated.div>
      </div>
    </section>
  );
};

export default AboutUs;
