import React from "react";
import { Helmet } from 'react-helmet';
import AllBlogs from "./Sections/AllBlogs";

function Blog() {
    return (
        <div>
         <Helmet>
  <title>Vargo Blog - Guías y Consejos Expertos en Seguros y Gestión de Riesgos</title>
  <meta name="description" content="Explora el Blog de Vargo para obtener información valiosa y consejos expertos en seguros y gestión de riesgos. Mantente al día con las últimas tendencias y noticias del sector en México." />
  <meta name="keywords" content="Blog de Seguros, Consejos de Seguros, Gestión de Riesgos, Tendencias de Seguros, Noticias del Sector de Seguros, Seguros en México" />
  <meta name="author" content="Vargo Seguros" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://vargo.mx/blog" />
  <meta property="og:title" content="Vargo Blog - Guías y Consejos Expertos en Seguros y Gestión de Riesgos" />
  <meta property="og:description" content="Descubre guías, consejos y las últimas noticias en el Blog de Vargo, tu fuente de información confiable para todo lo relacionado con seguros y gestión de riesgos en México." />
  <meta property="og:url" content="https://vargo.mx/blog" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="%PUBLIC_URL%/logo192.jpg" />
  <meta property="og:site_name" content="Vargo Seguros" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Vargo Blog - Guías y Consejos Expertos en Seguros y Gestión de Riesgos" />
  <meta name="twitter:description" content="Explora el Blog de Vargo para obtener información valiosa y consejos expertos en seguros y gestión de riesgos. Mantente al día con las últimas tendencias y noticias del sector en México." />
  <meta name="twitter:image" content="%PUBLIC_URL%/logo192.jpg" />
  <meta name="twitter:site" content="@VargoSeguros" />
  <meta name="twitter:creator" content="@VargoSeguros" />
</Helmet>

          <AllBlogs />
        </div>
    );
}

export default Blog;
