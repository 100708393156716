import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HeroBanner from './Sections/HeroBanner';
import AboutUs from './Sections/AboutUs'; 
import ServicesSection from './Sections/ServicesSection';
import SocialProof from './Sections/SocialSetion';
import BusinessPartnersSection from './Sections/BusinessPartnersSection';
import DifferencesSection from './Sections/DifferenceSection';
import BlogSection from './Sections/BlogPost';

function HomePage() {
  const location = useLocation();

  useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const scrollTo = queryParams.get('scrollTo');
      if (scrollTo === 'differences-section') {
          const section = document.getElementById('differences-section');
          if (section) {
              section.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, [location]);
    return (
        <div className='Homepage'>
          <HeroBanner />
          <AboutUs />
          <ServicesSection />
          <SocialProof />
          <DifferencesSection />
          <BusinessPartnersSection />
          <BlogSection />
        </div>
    );
}

export default HomePage;
