import React, { useEffect, useState } from 'react';
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import '../../styles/differencesSection.css'; // Asegúrate de que este archivo CSS exista
import DifferenceCard from '../DifferenceCard'; // Asume que tienes un componente similar a ServiceCard

const client = createClient({
  projectId: 'r6d5009o',
  dataset: 'production',
  apiVersion: '2023-11-05', // Usa la fecha actual en formato 'AAAA-MM-DD'
  useCdn: true,
});

// Configura la función constructora de URL para imágenes
const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const DifferencesSection = () => {
  const [differences, setDifferences] = useState([]);

  useEffect(() => {
    client.fetch('*[_type == "differenceCard"]').then((data) => {
      setDifferences(data);
    });
  }, []);

  return (
    <section className="differences-section" id='differences-section'>
      <h2>Nuestra Diferencia</h2>
      <p className="differences-intro">
        En Vargo, no solo ofrecemos servicios de seguros, sino que somos pioneros en integrar innovación y personalización en cada una de nuestras soluciones. Nuestra singularidad en el mercado se refleja en nuestro enfoque práctico y nuestra constante búsqueda de la excelencia para nuestros clientes.
      </p>
      <div className="differences-container">
        {differences.map((difference) => (
          <DifferenceCard
            key={difference._id}
            title={difference.title}
            text={difference.text}
            icon={urlFor(difference.icon).url()} // Suponiendo que 'icon' es el campo de imagen en tu esquema
          />
        ))}
      </div>
    </section>
  );
};

export default DifferencesSection;
