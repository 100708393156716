import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/header.css';
import logo from '../logo.png';

const Header = () => {
    const navigate = useNavigate(); // useNavigate is now inside the Header component
    const [isScrolled, setIsScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const scrollToSection = () => {
        if (window.location.pathname !== '/') {
            // Navigate to home page with query parameter
            navigate('/?scrollTo=differences-section');
        } else {
            // Scroll to the section if already on the home page
            const section = document.getElementById('differences-section');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };
    

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 10) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const toggleMenu = (event) => {
        event.stopPropagation();
        setMenuOpen(!menuOpen);
        const header = document.querySelector('.header');
        const hamburgerMenu = document.querySelector('.hamburger-menu');
        if (!menuOpen) {
            header.style.backgroundColor = '##ffffffe8';
            header.style.backdropFilter = 'blur(7px)';
            hamburgerMenu.classList.add('open');
        } else {
            header.style.backgroundColor = '';
            header.style.backdropFilter = '';
            hamburgerMenu.classList.remove('open');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={isScrolled ? 'header scrolled' : 'header'}>
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="Logo" className="logo" />
                </Link>
            </div>
            <div className="desktop">
                <nav className="navigation">
                    <ul>
                        <Link to="/nosotros">Acerca de Nosotros</Link>
                        <li>
                            <Link to="/servicios">Servicios y Ventajas</Link>
                        </li>
                        <li><Link to="/blog">BLOG</Link></li>
                        <li><Link to="/contact">Contáctanos</Link></li>
                    </ul>
                </nav>
            </div>

            <div className="mobile">
                <button className="hamburger-menu" onClick={toggleMenu} aria-label="Open menu">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </button>
                <nav className={`navigation ${menuOpen ? 'open' : ''}`}>
                    <ul>
                        <li><Link to="/nosotros">Acerca de Nosotros</Link></li>
                        <li><Link to="/servicios">Servicios y Ventajas</Link></li>
                        <li><Link to="/blog">BLOG</Link></li>
                        <li><Link to="/contact">Contáctanos</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
