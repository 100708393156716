import React from 'react';
import { useSpring, animated } from 'react-spring';

const DifferenceCard = ({ title, text, icon }) => {
  // Animation for the card
  const fadeInUp = useSpring({
    from: { opacity: 0, transform: 'translate3d(0,30px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0,0)' },
    delay: 200,
  });

  return (
    <animated.div style={fadeInUp} className="service-card">
      <picture className="service-icon">
        <source srcSet={icon.replace('.jpg', '.webp')} type="image/webp" />
        <source srcSet={icon} type="image/jpeg" /> {/* Assuming original is JPEG */}
        <img src={icon} alt={`${title} icon`} />
      </picture>
      <h3>{title}</h3>
      <p>{text}</p>
    </animated.div>
  );
};

export default DifferenceCard;
