import React from 'react';
import { Helmet } from 'react-helmet';
import ContactPage from './ContactPage';


function Terminos() {
    return (
        <div>
          <Helmet>
    <title>Vargo - Términos y Condiciones</title>
    <meta name="description" content="Conoce los términos y condiciones de Vargo, tu aliado en seguros y gestión de riesgos en México. Entérate de las políticas y procedimientos que rigen nuestros servicios y tu relación con nosotros." />
    <meta name="keywords" content="Términos y Condiciones, Políticas de Seguros, Procedimientos de Gestión de Riesgos, Regulaciones de Seguros, Seguros en México, Compromiso de Vargo" />
    <meta name="author" content="Vargo Seguros" />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://vargo.mx/terms" />
    <meta property="og:title" content="Vargo - Términos y Condiciones" />
    <meta property="og:description" content="Lee los términos y condiciones de Vargo para una comprensión clara de nuestras políticas de seguros y procedimientos de gestión de riesgos. Estamos comprometidos con la transparencia y la claridad en todos nuestros servicios." />
    <meta property="og:url" content="https://vargo.mx/terms" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="%PUBLIC_URL%/logo192.jpg" />
    <meta property="og:site_name" content="Vargo Seguros" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Vargo - Términos y Condiciones" />
    <meta name="twitter:description" content="Lee los términos y condiciones de Vargo para una comprensión clara de nuestras políticas de seguros y procedimientos de gestión de riesgos. Estamos comprometidos con la transparencia y la claridad en todos nuestros servicios." />
    <meta name="twitter:image" content="%PUBLIC_URL%/logo192.jpg" />
    <meta name="twitter:site" content="@VargoSeguros" />
    <meta name="twitter:creator" content="@VargoSeguros" />
</Helmet>

          <div style={{textAlign: "center", maxWidth: "50%", margin: "0 auto"}}>
            <h1>Vargo - Términos de Uso</h1>
            <p>Bienvenido a Vargo. Al utilizar nuestro sitio web, usted acepta estos términos. Ofrecemos servicios de correduría de seguros y gestión de riesgos. Al usar nuestro sitio, usted acuerda no utilizar la información para fines ilegales. No somos responsables de los errores causados por información incorrecta proporcionada por los usuarios. Los contenidos del sitio son propiedad de Vargo y están protegidos por derechos de autor. Nos reservamos el derecho de modificar estos términos y notificaremos cualquier cambio.</p>
          </div>
          <div>
            <ContactPage />
          </div>
        </div>
    );
}

export default Terminos;
