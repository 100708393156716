import React from "react";
import ContactForm from "./ContactForm";
import "../styles/contactPage.css";
import logo from "../logo.png";
import { Helmet } from "react-helmet";
import LazyLoad from "react-lazy-load";

function ContactPage() {
  const mapEmbedURL1 =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.2607572777756!2d-99.224638011946!3d19.314488035373024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cdff8df78acfdb%3A0xbc609e8b5d7fab7d!2sWeWork%20Espacio%20de%20Oficinas%20%26%20Coworking!5e0!3m2!1sen!2smx!4v1702542521967!5m2!1sen!2smx";
  const mapEmbedURL2 =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.306972885786!2d-99.60923202366506!3d19.269012381975593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd8a4b5ccb3403%3A0x1779ce66e5a7ceae!2sBusiness%20Center%20Torre%20Palmas!5e0!3m2!1sen!2smx!4v1702542607679!5m2!1sen!2smx";

  return (
    <div style={{ padding: "20px" }}>
      <Helmet>
        <title>
          Contacta a Vargo - Expertos en Seguros y Gestión de Riesgos
        </title>
        <meta
          name="description"
          content="Contacta a Vargo para obtener asesoría experta en seguros y gestión de riesgos. Estamos aquí para ayudarte con soluciones personalizadas adaptadas a tus necesidades."
        />
        <meta
          name="keywords"
          content="Contacto Vargo, Asesoría en Seguros, Gestión de Riesgos, Seguros en México, Consulta sobre Seguros"
        />
        <meta name="author" content="Vargo Seguros" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://vargo.mx/contact" />
        <meta
          property="og:title"
          content="Contacta a Vargo - Expertos en Seguros y Gestión de Riesgos"
        />
        <meta
          property="og:description"
          content="Ponte en contacto con Vargo para descubrir cómo nuestras soluciones de seguros y gestión de riesgos pueden proteger lo que más valoras."
        />
        <meta property="og:url" content="https://vargo.mx/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.jpg" />
        <meta property="og:site_name" content="Vargo Seguros" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contacta a Vargo - Expertos en Seguros y Gestión de Riesgos"
        />
        <meta
          name="twitter:description"
          content="Ponte en contacto con Vargo para descubrir cómo nuestras soluciones de seguros y gestión de riesgos pueden proteger lo que más valoras."
        />
        <meta name="twitter:image" content="%PUBLIC_URL%/logo192.jpg" />
        <meta name="twitter:site" content="@VargoSeguros" />
        <meta name="twitter:creator" content="@VargoSeguros" />
      </Helmet>

      <h1>Contacta a un Agente</h1>
      <div className="flex">
        <div className="mainC">
          <div style={{ textAlign: "center" }}>
            <div className="svg-animation-Contact">
              <svg
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 1000 1000"
                style={{ enableBackground: "new 0 0 1000 1000" }}
              >
                {/* Stroke ring */}
                <circle className="st0" cx="500" cy="500" r="302.8">
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 500 500"
                    to="360 500 500"
                    dur="100s"
                    repeatCount="indefinite"
                  />
                </circle>
                {/* Inner ring */}
                <circle className="st1" cx="500" cy="500" r="237.7">
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 500 500"
                    to="360 500 500"
                    dur="40s"
                    repeatCount="indefinite"
                  />
                </circle>
                {/* Outer ring */}
                <circle className="st2" cx="500" cy="500" r="366.8">
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 500 500"
                    to="-360 500 500"
                    dur="50s"
                    repeatCount="indefinite"
                  />
                </circle>
                {/* Outer thin ring */}
                <circle className="st3" cx="500" cy="500" r="385.1" />
              </svg>
            </div>
          </div>
          <div>
            <div className="CenterFF">
              <p>
                <i className="fas fa-envelope"></i>
                <a
                  href="mailto:hola@vargo.mx"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <strong>hola@vargo.mx</strong>
                </a>
              </p>
            </div>

            <div className="CenterFF">
              <i className="fas fa-map-marker-alt"></i>
              <p>
                Av. Ignacio Comonfort 1312<br></br> La Providencia, 52177 San
                Francisco Coaxusco, EdoMex.
              </p>
            </div>
            <div className="CenterFF">
              <i className="fas fa-map-marker-alt"></i>
              <p>
                Anillo Perif. 3720, Jardines del Pedregal<br></br> Álvaro
                Obregón, 01900 Álvaro Obregón, CDMX{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="mainC">
          <div style={{ textAlign: "center" }}>
            <img src={logo} alt="Logo" className="logxs" />
          </div>
          <ContactForm />
        </div>
      </div>
      <div className="inHere">
        <div className="CenterFF">
          <i className="fas fa-map-marker-alt"></i>
          <h4>Business Center Torre Palmas - Metepec, EdoMex</h4>
        </div>
        <LazyLoad height={550} offsetVertical={300}>
          <iframe
            title="location-map-1"
            src={mapEmbedURL1}
            style={{ border: 0, width: "100%", height: "550px" }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </LazyLoad>

        <div className="CenterFF">
          <i className="fas fa-map-marker-alt"></i>
          <h4>WeWork Artz - Alvaro Obregon, CDMX</h4>
        </div>
        <LazyLoad height={550} offsetVertical={300}>
          <iframe
            title="location-map-2"
            src={mapEmbedURL2}
            style={{ border: 0, width: "100%", height: "550px" }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </LazyLoad>
      </div>
    </div>
  );
}

export default ContactPage;
