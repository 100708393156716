import React, { useState, useEffect } from "react";
import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import "../../styles/blogHome.css";
import { Link } from "react-router-dom";

const client = createClient({
  projectId: "r6d5009o",
  dataset: "production",
  apiVersion: "2023-11-05",
  useCdn: true,
});

const builder = imageUrlBuilder(client);

function urlFor(source) {
    if (!source) {
      console.error('No source provided for urlFor');
      return '';
    }
    return builder.image(source);
  }
  

  const BlogPost = ({ id, title, description, imageUrl, author }) => {
    // Ensure imageUrl is a valid URL
    const validImageUrl = imageUrl ? imageUrl : ''; // Fallback if imageUrl is undefined
  
    return (
      <Link to={`/blog/${id}`}>
        <div className="blog-post">
          <picture>
            <source srcSet={urlFor(validImageUrl).format('webp').url()} type="image/webp" />
            <img src={urlFor(validImageUrl).url()} alt={title} />
          </picture>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </Link>
    );
  };

const BlogSection = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .fetch('*[_type == "blogPost"] | order(_createdAt desc)[0...4]')
      .then((data) => {
        setPosts(data);
      });
  }, []);

  return (
    <section className="blog-section">
      <h2>Últimos Blogs</h2>
      <div className="blog-posts">
      {posts.map((post) => (
  <BlogPost
    key={post._id}
    id={post._id}
    title={post.title}
    description={post.description}
    imageUrl={post.image} // Pass the image asset directly
    author={post.author}
  />
))}
      </div>
      <button
        className="ctaAll"
        onClick={() => (window.location.href = "/blog")}
      >
        Ver todos los blogs
      </button>
    </section>
  );
};

export default BlogSection;
