import React from 'react';
import { Helmet } from 'react-helmet';
import QuienesSomos from './Sections/QuienesSomos';

function NosotrosPage() {
    return (
        <div>
          <Helmet>
    <title>Vargo - Expertos en Seguros y Soluciones Personalizadas en México</title>
    <meta name="description" content="Conozca a Vargo, líderes en el mercado mexicano de seguros con más de 40 años de experiencia. Ofrecemos soluciones de seguros personalizadas y un enfoque integral para la protección y seguridad de nuestros clientes." />
    <meta name="keywords" content="Seguros en México, Soluciones Personalizadas de Seguros, Experiencia en Seguros, Servicio Personalizado en Seguros, Sustentabilidad en Seguros, Asesoramiento en Seguros" />
    <meta name="author" content="Vargo Seguros" />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://vargo.mx/nosotros" />
    <meta property="og:title" content="Vargo - Expertos en Seguros y Soluciones Personalizadas en México" />
    <meta property="og:description" content="Descubre Vargo, pioneros en seguros personalizados con un enfoque de servicio integral y sostenible. Comprometidos con la protección y el bienestar de nuestros clientes en México y a nivel internacional." />
    <meta property="og:url" content="https://vargo.mx/nosotros" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="%PUBLIC_URL%/logo192.jpg" />
    <meta property="og:site_name" content="Vargo Seguros" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Vargo - Expertos en Seguros y Soluciones Personalizadas en México" />
    <meta name="twitter:description" content="Descubre Vargo, pioneros en seguros personalizados con un enfoque de servicio integral y sostenible. Comprometidos con la protección y el bienestar de nuestros clientes en México y a nivel internacional." />
    <meta name="twitter:image" content="%PUBLIC_URL%/logo192.jpg" />
    <meta name="twitter:site" content="@VargoSeguros" />
    <meta name="twitter:creator" content="@VargoSeguros" />
</Helmet>

          <QuienesSomos />
        </div>
    );
}

export default NosotrosPage;
