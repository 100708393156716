import React from 'react';
import '../../styles/soluciones.css'

const Soluciones = () => {
    return (
      <div className="soluciones-container">
        <div className="wrapper">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <Tabla titulo="Líneas Personales" items={["Gastos Médicos Mayores", "Gastos Médicos Menores", "Accidentes Personales", "Vida e Inversión", "Auto Individual", "Casa Habitación", "Responsabilidad Civil"]} />
        </div>
        <div className="wrapper">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <Tabla titulo="Líneas Comerciales" items={["Gastos Médicos Mayores", "Gastos Médicos Menores", "Accidentes Personales", "Vida", "Flotillas y Equipo Pesado", "Transporte de Mercancías", "Daños Empresariales", "Responsabilidad Civil", "Obra Civil y Equipo Contratista"]} />
        </div>
        <div className="wrapper">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <Tabla titulo="Riesgos Especiales" items={["Marine", "Líneas Financieras", "Aviación y Barcos", "Grandes Riesgos", "Coaseguro", "Reaseguro", "AntiSecuestro"]} />
        </div>
      </div>
    );
  };
  
  const Tabla = ({ titulo, items }) => (
    <div className="cardSolutions">
      <section className="top">
        <span className="u-l">{titulo}:</span>
      </section>
      <section className="bottom">
        <ul className="users">
          {items.map((item, index) => (
            <li key={index} className="user">
              <span className="user-name">{item}</span>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
  
  export default Soluciones;