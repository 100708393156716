import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import '../styles/contactForm.css';

function ContactForm() {
    const form = useRef();
    const [formData, setFormData] = useState({
        user_name: '',
        user_phone: '',
        user_email: '',
        tipo_de_seguro_personal: [],
        tipo_de_seguro_comercial: [],
        tipo_de_seguro_riesgos: [],
        message: '',
    });
    const [showPersonal, setShowPersonal] = useState(false);
    const [showComercial, setShowComercial] = useState(false);
    const [showRiesgos, setShowRiesgos] = useState(false);
    
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        const name = e.target.name;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked ? [...prevState[name], value] : prevState[name].filter(item => item !== value)
        }));
    };

    const formatInsuranceTypes = (typesArray) => {
        return typesArray.map(type => type).join(', ');
    };
    

    const sendEmail = (e) => {
        e.preventDefault();
        console.log(form.current);
    
        const formattedPersonal = formatInsuranceTypes(formData.tipo_de_seguro_personal);
        const formattedComercial = formatInsuranceTypes(formData.tipo_de_seguro_comercial);
        const formattedRiesgos = formatInsuranceTypes(formData.tipo_de_seguro_riesgos);
    
        const toSend = {
            user_name: formData.user_name,
            user_phone: formData.user_phone,
            user_email: formData.user_email,
            message: formData.message,
            tipo_de_seguro_personal: formattedPersonal,
            tipo_de_seguro_comercial: formattedComercial,
            tipo_de_seguro_riesgos: formattedRiesgos
        };
    
        emailjs.send('service_01h72ff', 'template_32a0r38', toSend, 'kJ_YwHwwG8_bnlFGb')
            .then((result) => {
                console.log(result.text);
                alert('Email sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('An error occurred. Please try again later.');
            });
    };
    

    const toggleDropdown = (dropdown) => {
        setShowPersonal(false);
        setShowComercial(false);
        setShowRiesgos(false);
        if (dropdown === 'personal') setShowPersonal(!showPersonal);
        if (dropdown === 'comercial') setShowComercial(!showComercial);
        if (dropdown === 'riesgos') setShowRiesgos(!showRiesgos);
    };


    return (
        <form ref={form} onSubmit={sendEmail} className="contact-form">
            <div className="form-group">
                <input type="text" name="user_name" placeholder="Nombre" value={formData.user_name} onChange={handleInputChange} required/>
            </div>
            <div className="form-group">
                <input type="tel" name="user_phone" placeholder="Teléfono" value={formData.user_phone} onChange={handleInputChange} required/>
            </div>
            <div className="form-group">
                <input type="email" name="user_email" placeholder="E-mail" value={formData.user_email} onChange={handleInputChange} required/>
            </div>
            <label className="tipo-de-seguro-label">Tipo de Seguro: </label>
            <div className="checkbox-group">
            
        
                <div className="form-group">
                    <label className={showPersonal ? 'open' : ''} onClick={() => toggleDropdown('personal')}>Líneas Personales</label>
                    {showPersonal && (
                        <div className='DropF'>
                            <button type="button" className="close-btn" onClick={() => setShowPersonal(false)}>X</button>
                            {["Gastos Médicos Mayores", "Gastos Médicos Menores", "Accidentes Personales", "Vida e Inversión", "Auto Individual", "Casa Habitación", "Responsabilidad Civil"].map(option => (
                                <label key={option}>
                                    <input
                                        type="checkbox"
                                        name="tipo_de_seguro_personal"
                                        value={option}
                                        checked={formData.tipo_de_seguro_personal.includes(option)}
                                        onChange={handleCheckboxChange}
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label className={showComercial ? 'open' : ''} onClick={() => toggleDropdown('comercial')}>Líneas Comerciales</label>
                    {showComercial && (
                        <div className='DropF'>
                            <button type="button" className="close-btn" onClick={() => setShowComercial(false)}>X</button>
                            {["Gastos Médicos Mayores Comercial", "Gastos Médicos Menores Comercial", "Accidentes Personales Comercial", "Vida Comercial", "Flotillas y Equipo Pesado", "Transporte de Mercancías", "Daños Empresariales", "Responsabilidad Civil Comercial", "Obra Civil y Equipo Contratista"].map(option => (
                                <label key={option}>
                                    <input
                                        type="checkbox"
                                        name="tipo_de_seguro_comercial"
                                        value={option}
                                        checked={formData.tipo_de_seguro_comercial.includes(option)}
                                        onChange={handleCheckboxChange}
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label className={showRiesgos ? 'open' : ''} onClick={() => toggleDropdown('riesgos')}>Riesgos Especiales</label>
                    {showRiesgos && (
                        <div className='DropF'>
                            <button type="button" className="close-btn" onClick={() => setShowRiesgos(false)}>X</button>
                            {["Marine", "Líneas Financieras", "Aviación y Barcos", "Grandes Riesgos", "Coaseguro", "Reaseguro", "AntiSecuestro"].map(option => (
                                <label key={option}>
                                    <input
                                        type="checkbox"
                                        name="tipo_de_seguro_riesgos"
                                        value={option}
                                        checked={formData.tipo_de_seguro_riesgos.includes(option)}
                                        onChange={handleCheckboxChange}
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            
    
            <div className="form-group">
                <textarea name="message" placeholder="Mensaje" value={formData.message} onChange={handleInputChange}></textarea>
            </div>
            <button type="submit" className="submit-button ctaAll">ENVIAR</button>
        </form>
    );    
}

export default ContactForm;
