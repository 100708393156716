import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "./ContactForm";
import "../styles/blogPostPage.css";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="read-more ctaAll" onClick={onClose}>
          X
        </button>
        {children}
      </div>
    </div>
  );
};

const client = createClient({
  projectId: "r6d5009o",
  dataset: "production",
  apiVersion: "2023-11-05",
  useCdn: true,
});

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

const BlogPostPage = () => {
  const [post, setPost] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [comments, setComments] = useState([]);
  // const [newComment, setNewComment] = useState("");
  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  const { id } = useParams();

  useEffect(() => {
    client.fetch(`*[_type == "blogPost" && _id == "${id}"]`).then((data) => {
      setPost(data[0]);
    });
  }, [id]);

  // const handleSubmitComment = () => {
  //     if (!name || !email || !newComment) {
  //         alert('Please fill in all fields.');
  //         return;
  //     }

  //     const commentData = {
  //         name,
  //         email,
  //         comment: newComment,
  //         postId: id,
  //     };
  //     submitComment(commentData);
  //     setNewComment("");
  //     setName("");
  //     setEmail("");
  // };

  // const submitComment = (commentData) => {
  //     client.create({
  //         _type: 'comment',
  //         post: {
  //             _type: 'reference',
  //             _ref: commentData.postId
  //         },
  //         name: commentData.name,
  //         email: commentData.email,
  //         comment: commentData.comment,
  //     }).then(() => {
  //         alert('Comment submitted successfully!');
  //         setComments([...comments, commentData.comment]); // Update the local state with the new comment
  //     }).catch((error) => {
  //         console.error('Error submitting comment', error);
  //         alert('Failed to submit comment. Please try again.');
  //     });
  // };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  if (!post) return <div>Loading...</div>;

  return (
    <div className="blog-post-page">
      <Helmet>
        <title>{post.title} - Vargo Blog</title>
        <meta name="description" content={post.description} />
        <meta
          name="keywords"
          content="Seguros, Gestión de Riesgos, México, Seguros de Vida, Seguros de Salud"
        />{" "}
        {/* Ajusta según el contenido */}
        <meta name="author" content="Vargo Seguros" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://vargo.mx/blog/${id}`} />
        <meta property="og:title" content={post.title + " - Vargo Blog"} />
        <meta property="og:description" content={post.description} />
        <meta property="og:url" content={`https://vargo.mx/blog/${id}`} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={urlFor(post.image).url()} />
        <meta property="og:site_name" content="Vargo Seguros" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title + " - Vargo Blog"} />
        <meta name="twitter:description" content={post.description} />
        <meta name="twitter:image" content={urlFor(post.image).url()} />
        <meta name="twitter:site" content="@VargoSeguros" />
        <meta name="twitter:creator" content="@VargoSeguros" />
      </Helmet>

      <h2>{post.title}</h2>
      <h3>{post.description}</h3>
      <p>{post.tags}</p>
      <picture>
        <source
          srcSet={urlFor(post.image).format("webp").url()}
          type="image/webp"
        />
        <img src={urlFor(post.image).url()} alt={post.title} />
      </picture>
      <p>Por: {post.author}</p>
      <div
        className="innerHtml"
        dangerouslySetInnerHTML={{ __html: post.content }}
      />
      <div className="flex">
        <div className="share-buttons flex50">
          <strong>Compartir</strong>
          <FacebookShareButton url={window.location.href}>
            <FontAwesomeIcon icon={faFacebookF} />
          </FacebookShareButton>
          <TwitterShareButton url={window.location.href}>
            <FontAwesomeIcon icon={faTwitter} />
          </TwitterShareButton>
          <EmailShareButton url={window.location.href}>
            <FontAwesomeIcon icon={faEnvelope} />
          </EmailShareButton>
        </div>
        <div className="flex50">
          {!isModalOpen && (
            <button className="read-more ctaAll" onClick={toggleModal}>
              Más Información
            </button>
          )}

          <Modal isOpen={isModalOpen} onClose={toggleModal}>
            <ContactForm />
          </Modal>
        </div>
      </div>
      <div className="flex">
        <a href="/blog">⬅ VOLVER A BLOGS</a>
      </div>

      {/* <div className="comment-section">
                <h3>Comments</h3>
                <ul>
                    {comments.map((comment, index) => (
                        <li key={index}>{comment}</li>
                    ))}
                </ul>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Your name" />
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your email" />
                <textarea value={newComment} onChange={(e) => setNewComment(e.target.value)} placeholder="Write a comment..." />
                <button onClick={handleSubmitComment}>Submit Comment</button>
            </div> */}
    </div>
  );
};

export default BlogPostPage;
