import React from "react";
import '../styles/infoCards.css'

const InfoCard = ({ title, text, category }) => {
    return (
        <div class="card">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="card-inner">

    <h2>{title} <br></br> <light>{category}</light></h2>
        <p>{text}</p>
    </div>
</div>
    );
  };
  
  export default InfoCard;