import React, { useState, useEffect, useRef } from "react";
import { createClient } from "@sanity/client";
import "../../styles/testimonials.css";

const client = createClient({
  projectId: "r6d5009o",
  dataset: "production",
  apiVersion: "2023-11-05",
  useCdn: true,
});

const Testimonial = ({
  quote,
  author,
  position,
  company,
  imageUrl,
  isActive,
}) => {
  // Apply a dynamic class or style based on if the slide is active
  const className = `testimonial-card ${isActive ? "active" : "inactive"}`;
  return (
<div className={className}>
  {imageUrl && (
    <picture className="testimonial-image">
      <source srcSet={imageUrl.replace('.jpg', '.webp')} type="image/webp" />
      <source srcSet={imageUrl} type="image/jpeg" /> {/* Assuming original is JPEG */}
      <img src={imageUrl} alt={author} />
    </picture>
  )}
  <div className="testimonial-content">
    <p className="testimonial-quote">“{quote}”</p>
    <p className="testimonial-author">{author}</p>
    <p className="testimonial-info">
      {position} en {company}
    </p>
  </div>
</div>

  );
};
const SocialProof = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(2);
  const containerRef = useRef();

  useEffect(() => {
    const fetchTestimonials = async () => {
      const testimonialQuery = `*[_type == "testimonial"]{
        _id,
        author,
        company,
        position,
        quote,
        "imageUrl": image.asset->url
      }`;
      const testimonialData = await client.fetch(testimonialQuery);
      setTestimonials(testimonialData);

      // Set the initial index and scroll position after testimonials are fetched
      if (testimonialData.length > 0) {
        const initialIndex = 2; // Set to 2 to account for the duplicates at the beginning
        setCurrentIndex(initialIndex);
        updateSlidePosition(initialIndex, true); // Update immediately without smooth scrolling
      }
    };

    fetchTestimonials();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && testimonials.length > 0) {
        const scrollLeft = containerRef.current.scrollLeft;
        const style = window.getComputedStyle(containerRef.current.firstChild);
        const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
        const slideWidth = containerRef.current.firstChild.offsetWidth + margin;
        const newCurrentIndex = Math.round(scrollLeft / slideWidth);

        setCurrentIndex(newCurrentIndex);
      }
    };

    // Add scroll event listener
    const testimonialsContainer = containerRef.current;
    if (testimonialsContainer) {
      testimonialsContainer.addEventListener('scroll', handleScroll);
    }

    // Cleanup function
    return () => {
      if (testimonialsContainer) {
        testimonialsContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [testimonials.length]);

  const updateSlidePosition = (index, immediate = false) => {
    if (containerRef.current && testimonials.length > 0) {
      const slide =
        containerRef.current.getElementsByClassName("testimonial-card")[index];
      const style = window.getComputedStyle(slide);
      const margin =
        parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      const slideWidth = slide.offsetWidth + margin;

      const scrollX = index * slideWidth; // Declared and used inside this block

      if (immediate) {
        containerRef.current.style.scrollBehavior = "auto";
        containerRef.current.scrollLeft = scrollX;
        // Force reflow/repaint before the next line
        // You can perform a no-op read to force a repaint
        /* eslint-disable no-unused-expressions */
        containerRef.current.offsetHeight;
        /* eslint-enable no-unused-expressions */
        containerRef.current.style.scrollBehavior = "smooth";
      } else {
        containerRef.current.style.scrollBehavior = "smooth";
        containerRef.current.scrollLeft = scrollX;
      }
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      let nextIndex = (prevIndex + 1) % (testimonials.length + 4);
      if (nextIndex === extendedTestimonials.length - 2) {
        // At the end, reset to the second item (first real item is now at index 2)
        setTimeout(() => updateSlidePosition(2, true), 0);
        return 2;
      } else {
        updateSlidePosition(nextIndex);
        return nextIndex;
      }
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      let nextIndex =
        (prevIndex - 1 + extendedTestimonials.length) %
        extendedTestimonials.length;
      if (nextIndex === 1) {
        // At the beginning, reset to the second-to-last item
        setTimeout(() => updateSlidePosition(testimonials.length, true), 0);
        return testimonials.length;
      } else {
        updateSlidePosition(nextIndex);
        return nextIndex;
      }
    });
  };

  useEffect(() => {
    if (testimonials.length) {
      // Update the current index to the initial index after testimonials are fetched
      const initialIndex = 2;
      setCurrentIndex(initialIndex);
      // Update the slide position
      updateSlidePosition(initialIndex, true);
    }
  }, [testimonials]);

  const extendedTestimonials = [
    // Two last items duplicated at the start
    testimonials[testimonials.length - 2],
    testimonials[testimonials.length - 1],
    ...testimonials,
    // Two first items duplicated at the end
    testimonials[0],
    testimonials[1],
  ];

  return (
    <section className="social-proof">
      <div className="testimonials-container" ref={containerRef}>
        {extendedTestimonials.map((testimonial, index) => (
          <Testimonial
            key={index}
            {...testimonial}
            isActive={currentIndex === index}
          />
        ))}
      </div>
      <button className="move movePrev" onClick={prevSlide}>
        &#171;
      </button>
      <button className="move moveNext" onClick={nextSlide}>
        &#187;
      </button>
    </section>
  );
};

export default SocialProof;
